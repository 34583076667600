(function ($) {

    $(window).on('load', function () {
        heroHomepageSwiper();
        serviceList();
        testimonialsSwiper();
        hightlightsSwiper();
        askQuestion();
        newsletter();
        contactForm();
        categoriesSwiperInsights();
        categoriesSwiperNews();
        newsCategorySelect();
        loadMoreInsights();
        loadMoreNews();
        searchInsightsInput();
        searchNewsInput();
        printPost();
        // loadExpertise();
        contactsSwiper();
        keyContactsSwiper();
        expertiseContent();
        videoEmbed();
        aboutStatNumber();
        historySwiper();
        scrollToSection();
        selectDropdown();
        filterTeam();
        search();
        mobileHeader();
        statsSwiper();
        menuActiveLink();
        leadMagnet();
        textBlockWithSidebar();

    });

    $(window).on('scroll', function () {
        var windowY = window.pageYOffset;

        if (windowY >= 50) {
            $('header').addClass('scrolled');
        } else {
            $('header').removeClass('scrolled');
        }
    });

    function textBlockWithSidebar() {
        if($('.text-block-with-sidebar').length > 0) {
            $(document).on('click', '.sidebar-item', function(e){
                if(!$(this).hasClass('active')) {
                    var triggerEl = $(this);
                    var triggerElAnchor = triggerEl.attr('data-anchor');
                    var triggerElKey = triggerEl.attr('data-item');
                    var holder = triggerEl.parents('.text-block-with-sidebar-holder');

                    var prevActiveEl = holder.find('.sidebar-item.active');
                    var prevActiveElAnchor = prevActiveEl.attr('data-anchor');
                    var prevActiveElKey = prevActiveEl.attr('data-item');

                    var prevBlock = holder.find('.text-block#' + prevActiveElAnchor + '[data-content-block="' + prevActiveElKey + '"]');
                    var thisBlock = holder.find('.text-block#' + triggerElAnchor + '[data-content-block="' + triggerElKey + '"]');

                    prevActiveEl.removeClass('active');
                    triggerEl.addClass('active');
                    prevBlock.slideUp(300, function(){
                        thisBlock.slideDown(300, function(){
                            prevBlock.removeClass('active');
                            thisBlock.addClass('active');
                        });
                    });
                }
            });

            $(document).on('click', '.text-block-mob-trigger', function(e){
                if(!$(this).parents('.text-block').hasClass('active')) {
                    var thisTrigger = $(this);
                    var thisTriggerAnchor = thisTrigger.parents('.text-block').attr('id');
                    var thisTriggerKey = thisTrigger.parents('.text-block').attr('data-content-block');
                    var holder = thisTrigger.parents('.text-block-with-sidebar-holder');

                    var prevTriggerBlock = holder.find('.text-block.active');
                    var prevTriggerBlockAnchor = prevTriggerBlock.attr('id');
                    var prevTriggerBlockKey = prevTriggerBlock.attr('data-content-block');

                    var prevSidebarEl = holder.find('.sidebar-item.active');
                    var nextSidebarEl = holder.find('.sidebar-item[data-item="' + thisTriggerKey + '"][data-anchor="' + thisTriggerAnchor + '"]');


                    prevTriggerBlock.find('.desc').slideUp(300, function(){
                        thisTrigger.parents('.text-block').find('.desc').slideDown(300, function(){
                            prevSidebarEl.removeClass('active');
                            nextSidebarEl.addClass('active');
                            prevTriggerBlock.removeClass('active');
                            thisTrigger.parents('.text-block').addClass('active');
                        });
                    });
                }


            });
        }
    }

    function heroHomepageSwiper() {
        if ($('.swiper-hero').length > 0) {
            var swiper = new Swiper('.swiper-hero', {
                speed: 400,
                slidesPerView: 1,
                navigation: {
                    prevEl: '.swiper-custom-prev',
                    nextEl: '.swiper-custom-next',
                }
            });
        }
    }

    function serviceList() {
        if ($('.single-industries-expertise').length) {
            $([document.documentElement, document.body]).stop().animate({
                scrollTop: $(".expertise-details").offset().top - 200
            }, 2000);
        }

        if ($('.services-holder').length > 0) {
            $(document).on('click', '.services-holder .tab-selection .tab-name', function(e){
                var this_el = $(this);
                var this_el_data = this_el.attr('data-tab');
                console.log(this_el);
                console.log(this_el_data);
                if(!this_el.hasClass('active')) {
                    var becomeing_active = $('.services-block[data-type="' + this_el_data + '"]');
                    var becomeing_deactive = $('.services-block').not($('.services-block[data-type="' + this_el_data + '"]'));

                    becomeing_deactive.addClass('scale-down');
                    setTimeout(function(){
                        becomeing_deactive.addClass('hide');
                        becomeing_active.removeClass('hide');
                    }, 350);
                    setTimeout(function(){
                        becomeing_active.removeClass('scale-down');
                    }, 400);
                    $('.services-holder .tab-selection .tab-name').not(this_el).removeClass('active');
                    this_el.addClass('active');
                }
            });
        }
        if ($('.services-list').length > 0) {
            $('.tab-block').addClass('active').each(function () {
                var height = $(this).outerHeight();
                $(this).attr('data-height', height);
                $(this).css('height', height);
            }).removeClass('active').first().addClass('active');



            $('.tab-selection .tab-name').on('click', function () {
                var el = $(this),
                    tab = el.data('tab');

                el.siblings().removeClass('active');
                el.addClass('active');
                $('.service-box').removeClass('active');
                // $('.expertise-details').empty();
                $('.tab-block').addClass('loading');
                setTimeout(function () {
                    $('.tab-block').css('height', $('.tab-block[data-tab="' + tab + '"]').data('height')).removeClass('active');
                    $('.tab-block[data-tab="' + tab + '"]').addClass('active');
                    setTimeout(function () {
                        $('.tab-block').removeClass('loading');
                    }, 300);
                }, 300);
            });
        }
    }

    function testimonialsSwiper() {
        if ($('.testimonial-swiper').length > 0) {
            var swiper = new Swiper('.testimonial-swiper', {
                slidesPerView: 1.2,
                spaceBetween: 15,
                speed: 700,
                centeredSlides: true,
                loop: false,
                navigation: {
                    prevEl: '.testimonial-swiper-custom-prev',
                    nextEl: '.testimonial-swiper-custom-next'
                },
                pagination: {
                    el: '.swiper-pagination-custom',
                    type: 'custom'
                },
                breakpoints: {
                    991: {
                        spaceBetween: 60,
                        slidesPerView: 'auto'
                    }
                }
            });
            if ($('.swiper-pagination-current').length > 0) {
                swiper.on('slideChange', function () {
                    if ((swiper.activeIndex + 1) > 9) {
                        $('.swiper-pagination-current').text((swiper.activeIndex + 1));
                    } else {
                        $('.swiper-pagination-current').text('0' + (swiper.activeIndex + 1));
                    }
                });
            }
        }
    }

    function hightlightsSwiper() {
        if ($('.swiper-highlights').length > 0) {
            var swiper = new Swiper('.swiper-highlights', {
                speed: 400,
                slidesPerView: 1,
                spaceBetween: 10,
                navigation: {
                    prevEl: '.swiper-custom-prev',
                    nextEl: '.swiper-custom-next',
                },
                breakpoints: {
                    991: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    }
                }
            });
        }
        if ($('.swiper-insights').length > 0) {
            var insightsSwiper = new Swiper('.swiper-insights', {
                speed: 400,
                slidesPerView: 1.05,
                spaceBetween: 10,
                navigation: {
                    prevEl: '.swiper-custom-prev-insights',
                    nextEl: '.swiper-custom-next-insights',
                },
                breakpoints: {
                    991: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    }
                }
            });
        }
        if ($('.swiper-recognition').length > 0) {
            var recognitionSwiper = new Swiper('.swiper-recognition', {
                speed: 400,
                slidesPerView: 1.05,
                spaceBetween: 10,
                navigation: {
                    prevEl: '.swiper-custom-prev-recognition',
                    nextEl: '.swiper-custom-next-recognition',
                },
                breakpoints: {
                    991: {
                        slidesPerView: 2,
                        spaceBetween: 32,
                    }
                }
            });
        }
    }

    function contactsSwiper() {
        if ($('.swiper-contacts').length > 0) {
            var swiper = new Swiper('.swiper-contacts', {
                speed: 400,
                slidesPerView: 1,
                spaceBetween: 10,
                navigation: {
                    prevEl: '.contact-swiper-custom-prev',
                    nextEl: '.contact-swiper-custom-next',
                },
                breakpoints: {
                    500: {
                        slidesPerView: 2,
                        spaceBetween: 24,
                    }
                }
            });
        }
    }

    function keyContactsSwiper() {
        if ($('.swiper-key').length > 0) {
            var swiper = new Swiper('.swiper-key', {
                speed: 400,
                slidesPerView: 1,
                spaceBetween: 10,
                navigation: {
                    prevEl: '.keyContact-swiper-custom-prev',
                    nextEl: '.keyContact-swiper-custom-next',
                },
                breakpoints: {
                    500: {
                        slidesPerView: 2,
                        spaceBetween: 24,
                    }
                }
            });
        }
    }

    function searchNewsInput() {
        if ($('.page-template-template-news').length !== 0) {
            $('.search-input.news').on('change, keyup', delay(function (e) {
                getNews($(this).val(), $('.filter-section input[name="industries"]').val(), $('.filter-section input[name="year-from"]').val(), $('.filter-section input[name="year-to"]').val());
            }, 1400));

            $('.filter-section input[name="industries"]').on('change', function () {
                getNews($('.search-input.news').val(), $(this).val(), $('.filter-section input[name="year-from"]').val(), $('.filter-section input[name="year-to"]').val());
            });

            $('.filter-section input[name="year-from"]').on('change', function () {
                getNews($('.search-input.news').val(), $('.filter-section input[name="industries"]').val(), $(this).val(), $('.filter-section input[name="year-to"]').val());
            });

            $('.filter-section input[name="year-to"]').on('change', function () {
                getNews($('.search-input.news').val(), $('.filter-section input[name="industries"]').val(), $('.filter-section input[name="year-from"]').val(), $(this).val());
            });

            $('.filter-button').on('click', function () {
                $(this).toggleClass('active');
                $('.filter-section').toggleClass('active');
            });
        }
    }

    function newsCategorySelect() {
        //Category selection
        // $('.year-from').yearpicker();

        $(document).on('click', '.categories .category', function () {
            $('.categories .category').removeClass('active');
            $(this).addClass('active');

            var slug = $(this).find('.category-title').attr('data-slug');
            if (slug != 'all') {
                $('.news-card').addClass('hidden');
                $('.news-card[data-slug~="' + slug + '"]').removeClass('hidden');
                $('.load-more-news').closest('.load-more-container').removeClass('active');
            } else {
                $('.load-more-news').closest('.load-more-container').addClass('active');
            }
        });
    }

    function getNews(search, category, yearFrom, yearTo) {
        var categories = category.split(',');
        var data = {
            'action': 'load_news',
            'search': search,
            'year_from': yearFrom,
            'year_to': yearTo,
            'category': categories ? categories : ''
        };

        $('.post-list').empty();
        $('.load-more-container').removeClass('active');
        $('.no-posts').removeClass('active');
        $('.loading-screen').addClass('active');

        $.get(postmpwp.ajax_url, data, function (response) {
            $('.post-list').empty();

            if (response.success) {
                $('.categories').addClass('active');
                $('.load-more-news').removeClass('loading');
                $('.loading-screen').removeClass('active');
                $('.post-list').append(response.data.news);

                var visiblePostsCount = $('.news-card:not(.hidden)').length,
                    allPosts = $('.news-card').length;

                $('.post-list').attr('data-all', allPosts).attr('data-visible', visiblePostsCount);

                if (response.data.count > 11) {
                    $('.load-more-container').addClass('active');
                }
            } else {
                $('.loading-screen').removeClass('active');
                $('.no-posts').addClass('active');
                $('.categories').removeClass('active');
            }
        });
    }

    function loadMoreNews() {
        $(document).on('click', '.load-more-news', function () {
            var categories = $('.filter-section input[name="industries"]').val();
            var visiblePostsCount = $('.news-card:not(.hidden)').length,
                allPosts = $('.news-card').length,
                addingNumber = 12;

            var data = {
                'action': 'load_more_news',
                'search': $('.search-input.news').val(),
                'category': categories,
                'year_from': $('.filter-section input[name="year-from"]').val(),
                'year_to': $('.filter-section input[name="year-to"]').val(),
                'offset': allPosts,
                'member': $('.post-list').attr('data-member')
            };

            $('.no-posts').removeClass('active');
            $('.load-more-news').addClass('loading');

            $('.news-card').each(function (index) {
                if (index < (visiblePostsCount + addingNumber)) {
                    $(this).removeClass('hidden');
                }
            });

            if ((visiblePostsCount + addingNumber) >= allPosts) {
                $('.load-more-news').closest('.load-more-container').removeClass('active');
            }

            $.get(postmpwp.ajax_url, data, function (response) {
                if (response.success) {
                    visiblePostsCount = $('.news-card:not(.hidden)').length;
                    allPosts = $('.news-card').length;
                    $('.loading-screen').removeClass('active');
                    $('.post-list').append(response.data.news);
                    $('.post-list').attr('data-all', allPosts).attr('data-visible', visiblePostsCount);
                    $('.load-more-news').removeClass('loading');
                } else {
                    $('.loading-screen').removeClass('active');
                }
            });
        });
    }

    function delay(callback, ms) {
        var timer = 0;
        return function () {
            var context = this, args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                callback.apply(context, args);
            }, ms || 0);
        };
    }

    function categoriesSwiperInsights() {
        if ($('.categories-swiper-insights').length > 0) {
            var swiper = new Swiper('.categories-swiper-insights', {
                initialSlide: $('.swiper-slide').length,
                spaceBetween: 20,
                slidesPerView: 1.3,
                height: 'auto',
                speed: 700,
                centeredSlides: true,
                slideToClickedSlide: true,
                navigation: {
                    prevEl: '.swiper-custom-prev',
                    nextEl: '.swiper-custom-next'
                },
                breakpoints: {
                    991: {
                        spaceBetween: 50,
                        slidesPerView: 3.5,
                    }
                },
                on: {
                    init: function () {
                        setTimeout(function () {
                            $('.swiper').addClass('active');
                            swiper.slideTo(0, 2000);
                            $('.swiper-slide').first().addClass('active');
                        }, 500);
                    },
                },
            });

            //Category selection
            $('.categories-swiper-insights .swiper-slide').on('click', function () {
                $('.categories-swiper-insights .swiper-slide').removeClass('active');
                $(this).addClass('active');
                $('.load-more-insights').closest('.load-more-container').removeClass('active');

                var slug = $(this).find('.category-title').attr('data-slug');
                if (slug != 'all') {
                    $('.insight-card').addClass('hidden');
                    $('.featured-post').addClass('hidden');

                    $('.insight-card[data-slug~="' + slug + '"]').removeClass('hidden');
                    $('.featured-post[data-slug~="' + slug + '"]').removeClass('hidden');
                    $('.featured-post').removeClass('featured-post').addClass('insight-card');
                    $('.real-featured-post').addClass('featured-post').removeClass('insight-card');
                    if ($('.real-featured-post').hasClass('hidden')) {
                        $('.insight-card:not(.hidden)').first().addClass('featured-post').removeClass('insight-card');
                    }
                } else {
                    $('.featured-post').removeClass('hidden');
                    $('.insight-card').removeClass('hidden');
                    $('.featured-post').removeClass('featured-post').addClass('insight-card');
                    $('.real-featured-post').addClass('featured-post').removeClass('insight-card');
                }
            });
        }
    }

    function categoriesSwiperNews() {
        var clear;
        if ($('body').hasClass('lt')) {
            clear = 'Išvalyti';
        } else {
            clear = 'Clear';
        }
        $('.year-from').yearpicker({
            startYear: 2009,
            endYear: new Date().getFullYear(),
            template: `<div class="yearpicker-container">
                    <div class="yearpicker-header">
                        <div class="yearpicker-current" data-view="yearpicker-current">SelectedYear</div>
                        <div class="wrap">
                         <div class="yearpicker-prev" data-view="yearpicker-prev">&lsaquo;</div>
                        <div class="yearpicker-next" data-view="yearpicker-next">&rsaquo;</div>
                    </div>
                    </div>
                    <div class="yearpicker-body">
                        <ul class="yearpicker-year" data-view="years">
                        </ul>
                    </div>
                     <div class="yearpicker-footer">
                        <div class="yearpicker-clear-from">` + clear + `</div>
</div>
                </div>
`,
        });
        $('.year-to').yearpicker({
            startYear: 2009,
            endYear: new Date().getFullYear(),
            template: `<div class="yearpicker-container">
                      <div class="yearpicker-header">
                        <div class="yearpicker-current" data-view="yearpicker-current">SelectedYear</div>
                        <div class="wrap">
                         <div class="yearpicker-prev" data-view="yearpicker-prev">&lsaquo;</div>
                        <div class="yearpicker-next" data-view="yearpicker-next">&rsaquo;</div>
                    </div>
                    </div>
                    <div class="yearpicker-body">
                        <ul class="yearpicker-year" data-view="years">
                        </ul>
                    </div>
                    <div class="yearpicker-footer">
                        <div class="yearpicker-clear-to">` + clear + `</div>
</div>
                </div>
`,
        });

        $('.yearpicker-clear-from').on('click', function () {
            $('.year-from').val('').trigger('change');
        });
        $('.yearpicker-clear-to').on('click', function () {
            $('.year-to').val('').trigger('change');
        });

        if ($('.categories-swiper-news').length > 0) {
            var swiper = new Swiper('.categories-swiper-news', {
                initialSlide: $('.swiper-slide').length,
                spaceBetween: 20,
                slidesPerView: 1.3,
                height: 'auto',
                speed: 700,
                centeredSlides: true,
                slideToClickedSlide: true,
                navigation: {
                    prevEl: '.swiper-custom-prev',
                    nextEl: '.swiper-custom-next'
                },
                breakpoints: {
                    991: {
                        spaceBetween: 50,
                        slidesPerView: 3.5,
                    }
                },
                on: {
                    init: function () {
                        setTimeout(function () {
                            $('.swiper').addClass('active');
                            swiper.slideTo(0, 2000);
                            $('.swiper-slide').first().addClass('active');
                        }, 500);
                    },
                },
            });

            //Category selection
            $('.categories-swiper-news .swiper-slide').on('click', function () {
                $('.categories-swiper-news .swiper-slide').removeClass('active');
                $(this).addClass('active');

                var slug = $(this).find('.category-title').attr('data-slug');
                if (slug != 'all') {
                    $('.post-list').attr('data-category', slug);
                    getNews($('.search-input').val(), slug);
                } else {
                    $('.post-list').attr('data-category', '');
                    getNews($('.search-input').val());
                }
            });
        }
    }

    function searchInsightsInput() {
        if ($('.page-template-template-insights').length !== 0) {
            $('.search-input.insights').on('change, keyup', delay(function (e) {
                getInsights($(this).val(), $('.filter-section input[name="categories"]').val(), $('.filter-section input[name="year-from"]').val(), $('.filter-section input[name="year-to"]').val());
            }, 800));

            $('.filter-section input[name="categories"]').on('change', delay(function (e) {
                getInsights($('.search-input.insights').val(), $(this).val(), $('.filter-section input[name="year-from"]').val(), $('.filter-section input[name="year-to"]').val());
            }, 800));

            $('.filter-section input[name="year-from"]').on('change', function () {
                getInsights($('.search-input.insights').val(), $('.filter-section input[name="categories"]').val(), $(this).val(), $('.filter-section input[name="year-to"]').val());
            });

            $('.filter-section input[name="year-to"]').on('change', function () {
                getInsights($('.search-input.insights').val(), $('.filter-section input[name="categories"]').val(), $('.filter-section input[name="year-from"]').val(), $(this).val());
            });

            $('.filter-button').on('click', function () {
                $(this).toggleClass('active');
                $('.filter-section').toggleClass('active');
            });
        }
    }

    function loadMoreInsights() {
        $(document).on('click', '.load-more-insights', function () {
            var visiblePostsCount = $('.insight-card:not(.hidden)').length,
                allPosts = $('.insight-card').length,
                addingNumber = 6;

            $('.insight-card').each(function (index) {
                if (index < (visiblePostsCount + addingNumber)) {
                    $(this).removeClass('hidden');
                }
            });
            if ((visiblePostsCount + addingNumber) >= allPosts) {
                $('.load-more-insights').closest('.load-more-container').removeClass('active');
            }
        });
    }

    function getInsights(search, category, yearFrom, yearTo) {
        var data = {
            'action': 'load_insights',
            'search': search,
            'year_from': yearFrom,
            'year_to': yearTo,
            'category': category,
        };

        $('.post-list').empty();
        $('.categories').removeClass('active');
        $('.load-more-container').removeClass('active');
        $('.no-posts').removeClass('active');
        $('.loading-screen').addClass('active');

        $.get(postmpwp.ajax_url, data, function (response) {
            $('.post-list').empty();

            if (response.success) {
                $('.loading-screen').removeClass('active');
                $('.post-list').append(response.data.insights);
                if (response.data.count > 6) {
                    $('.load-more-container').addClass('active');
                }
                if (search == '') {
                    $('.swiper-slide').removeClass('active').first().addClass('active');
                    $('.categories').addClass('active');
                }
            } else {
                $('.loading-screen').removeClass('active');
                $('.no-posts').addClass('active');
            }
        });
    }

    function newsletter() {
        var email = $('.newsletter-form input[name="email"]'),
            category = $('.newsletter-form input[name="category"]'),
            visualCategory = $('.newsletter-form .select-dropdown'),
            acceptance = $('.newsletter-form .acceptance');

        $('.newsletter-popup-button').on('click', function () {
            $('.newsletter-popup').addClass('active');
        });

        $('.newsletter-content .close-icon').on('click', function () {
            $('.newsletter-popup').removeClass('active');
            $('.newsletter-popup .success').removeClass('active');
        });

        $('.acceptance').on('click', function () {
            $(this).find('.custom-checkbox').toggleClass('active').trigger('change');
            $('.select-dropdown').removeClass('active');
        });

        email.on('change', function () {
            email.removeClass('error');
        });

        category.on('change', function () {
            visualCategory.removeClass('error');
        });

        acceptance.find('.custom-checkbox').on('change', function () {
            acceptance.find('.custom-checkbox').removeClass('error');
        });

        $('.submit-newsletter').on('click', function () {
            var valid = true;

            if ((email.val().trim() === '') || !validateEmail(email.val().trim())) {
                valid = false;
                email.addClass('error');
            }

            if ((category.val().trim() === '')) {
                valid = false;
                visualCategory.addClass('error');
            }

            if (acceptance.hasClass('required') && !acceptance.find('.custom-checkbox').hasClass('active')) {
                valid = false;
                acceptance.find('.custom-checkbox').addClass('error');
            }

            if (valid) {
                $('.submit-newsletter').addClass('loading');
                var data = {
                    'action': 'add_subscriber',
                    'groupID': category.val().trim(),
                    'email': email.val().trim()
                };

                $.get(postmpwp.ajax_url, data, function (response) {
                    if (response.success) {
                        $('.newsletter-popup .success').addClass('active');
                        email.val('');
                        category.val('').trigger('change');
                        acceptance.find('.custom-checkbox').removeClass('active').trigger('change');
                        $('.select-dropdown-option .custom-checkbox').removeClass('active');
                        $('.select-dropdown-top').text('Category').removeClass('selected');
                        $('.submit-newsletter').removeClass('loading');
                    }
                });
            }
        });
    }

    function contactForm() {
        var email = $('.contact-section input[name="your-email"]'),
            category = $('.contact-section input[name="category"]'),
            messageInput = $('.contact-section textarea[name="your-message"]'),
            visualCategory = $('.contact-section .select-dropdown');

        email.on('change', function () {
            email.removeClass('error');
        });

        category.on('change', function () {
            visualCategory.removeClass('error');
        });

        messageInput.on('change', function () {
            messageInput.removeClass('error');
        });

        $('.submit-contactForm').on('click', function () {
            var valid = true;

            if ((email.val().trim() === '') || !validateEmail(email.val().trim())) {
                valid = false;
                email.addClass('error');
            }

            if ((messageInput.val().trim() === '')) {
                valid = false;
                messageInput.addClass('error');
            }

            if ((category.val().trim() === '')) {
                valid = false;
                visualCategory.addClass('error');
            }

            if (valid) {
                $('.submit-contactForm').addClass('loading');
                $('.contact-section .wpcf7-submit').click();
            }
        });
        $(document).on('wpcf7invalid ', function () {
            $('.submit-contactForm').removeClass('loading');
        });
        $(document).on('wpcf7mailsent ', function () {
            $('.contact-section .success').addClass('active');
            $('.submit-contactForm').removeClass('loading');
            $('.select-dropdown-option .custom-checkbox').removeClass('active');
            $('.contact-section .select-dropdown-top').removeClass('selected').text($('.contact-section .select-dropdown-top').attr('data-placeholder'));
            category.val('');
        });
        $(document).on('wpcf7mailfailed ', function () {
            $('.submit-contactForm').removeClass('loading');
            $('.contact-section input').val('');
        });
    }

    function printPost() {
        $('.social-icon.email').on('click', function () {
            printWindow();
        });
    }

    function printWindow() {
        // var divToPrint = $('.wysiwyg')[0].innerHTML;
        // var popupWin = window.open('', '_blank', 'width=720,height=300');
        // popupWin.document.open();
        // popupWin.document.write('<html><body onload="window.print()">' + divToPrint + '</html>');
        // popupWin.document.close();
        window.print();
    }

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    function askQuestion() {
        $('.ask-question-button').on('click', function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $('.question-box').removeClass('active');
                setTimeout(function () {
                    $('.question-box .success').removeClass('active');
                }, 500);
            } else {
                $(this).addClass('active');
                $('.question-box').addClass('active');
            }
        });
        $('.question-box .button').on('click', function () {
            $(this).addClass('loading');
            $('.question-box .wpcf7-submit').click();
        });

        $(document).on('wpcf7invalid ', function () {
            $('.question-box .button').removeClass('loading');
        });
        $(document).on('wpcf7mailsent ', function () {
            $('.question-box .success').addClass('active');
            $('.question-box .button').removeClass('loading');
        });
        $(document).on('wpcf7mailfailed ', function () {
            $('.question-box .button').removeClass('loading');
            $('.question-box .input').val('');
        });
    }

    //
    // var url = new URL(window.location);
    // var urlParams = (new URL(document.location)).searchParams;
    //
    // function updateUrlParams(param, value) {
    //     if (value) {
    //         urlParams.set(param, value);
    //
    //     } else {
    //         urlParams.delete(param);
    //     }
    //
    //     url.search = urlParams.toString();
    //
    //     history.replaceState({}, null, url.toString());
    // }
    //
    // var getUrlParameter = function getUrlParameter(sParam) {
    //     var sPageURL = window.location.search.substring(1),
    //         sURLVariables = sPageURL.split('&'),
    //         sParameterName,
    //         i;
    //
    //     for (i = 0; i < sURLVariables.length; i++) {
    //         sParameterName = sURLVariables[i].split('=');
    //
    //         if (sParameterName[0] === sParam) {
    //             return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    //         }
    //     }
    //     return false;
    // };

    // function loadExpertise() {
    //     var expertise = getUrlParameter('q');
    //     var slug = getUrlParameter('slug');
    //
    //     if (expertise) {
    //         setTimeout(function () {
    //             if (slug === 'industry') {
    //                 $('.tab-name[data-tab="industries"]').click();
    //             }
    //             $('.service-box[data-href="' + expertise + '"]').click();
    //         }, 100);
    //     }
    //
    //     if ($('.selected-slug').length > 0) {
    //         setTimeout(function () {
    //             var slug = $('.selected-slug').attr('data-url');
    //             $('.tab-name[data-tab="' + slug + '"]').click();
    //         }, 10);
    //     }
    //     if ($('.selected-id').length > 0) {
    //         setTimeout(function () {
    //             var id = $('.selected-id').attr('data-url');
    //             $('.service-box[data-id="' + id + '"]').click();
    //         }, 100);
    //     }
    //     $('.service-box').on('click', function () {
    //         updateUrlParams('q', $(this).attr('data-href'));
    //         updateUrlParams('slug', $(this).attr('data-slug'));
    //         $('.loading-screen').addClass('active');
    //
    //         $([document.documentElement, document.body]).stop().animate({
    //             scrollTop: $(".loading-screen").offset().top - 200
    //         }, 2000);
    //
    //         $('.service-box').removeClass('active');
    //         $(this).addClass('active');
    //         var id = $(this).attr('data-id');
    //         $('.expertise-details').empty();
    //         var data = {
    //             'action': 'load_expertise',
    //             'id': id,
    //         };
    //
    //         $.get(postmpwp.ajax_url, data, function (response) {
    //             if (response.success) {
    //                 $('.expertise-details').empty().append(response.data.content);
    //                 $('.expertise-details .wysiwyg').addClass('active');
    //                 expertiseContent();
    //                 hightlightsSwiper();
    //                 testimonialsSwiper();
    //                 contactsSwiper();
    //                 keyContactsSwiper();
    //                 $('.expertise-details .wysiwyg').removeClass('active').first().addClass('active');
    //                 $('.expertise-details .why-our').addClass('active');
    //                 $('.loading-screen').removeClass('active');
    //             } else {
    //                 $('.loading-screen').removeClass('active');
    //             }
    //         });
    //     });
    // }

    function expertiseContent() {
        $('.content-select-mobile .wysiwyg').addClass('active').each(function () {
            var height = $(this).outerHeight();
            $(this).css('height', height);
            $(this).removeClass('active');
        }).first().addClass('active');

        $(document).on('click', '.content-select .item', function () {
            $('.item').removeClass('active');
            $('.wysiwyg').removeClass('active');
            $(this).addClass('active');
            $('.wysiwyg[data-content="' + $(this).attr('data-content') + '"]').addClass('active');
        });
        $(document).on('click', '.content-select-mobile .item', function (e) {
            setTimeout(function () {
                $([document.documentElement, document.body]).stop().animate({
                    scrollTop: $(e.target).offset().top - 200
                }, 1000);
            }, 500);
            $('.item').removeClass('active');
            $('.wysiwyg').removeClass('active');
            $(this).addClass('active');
            $('.wysiwyg[data-content="' + $(this).attr('data-content') + '"]').addClass('active');
        });
    }

    function videoEmbed() {
        if ($('.videoEmbed').length > 0) {
            $('main').addClass('news-inner-page');
        }
        $('.videoEmbed').on('click', function () {
            $('.embed-popup').addClass('active');
        });

        $('.embed-popup .close-icon').on('click', function () {
            $('.embed-popup').removeClass('active');
            $('.embed-popup iframe').attr('src', $('.embed-popup iframe').attr('src'));
        });

        $('.embed-popup').on('click', function (e) {
            if ($(e.target).closest('iframe').length === 0) {
                $('.embed-popup').removeClass('active');
                $('.embed-popup iframe').attr('src', $('.embed-popup iframe').attr('src'));
            }
        });
    }

    function aboutStatNumber() {
        $('.stat-box').each(function () {
            var number = $(this).find('.number');

            $(number).prop('Counter', 0).animate({
                Counter: parseInt($(this).attr('data-number'))
            }, {
                duration: 3000,
                easing: 'swing',
                step: function (now) {
                    $(this).text(now.toFixed(0));
                }
            });
        });
    }

    function historySwiper() {
        $('.history-slide').each(function () {
            $(this).addClass('active');
            var height = $(this).outerHeight(true);
            $(this).css('height', height);
            $(this).removeClass('active');
        });

        $('.history-slide').first().addClass('active');

        if ($('.year-swiper').length > 0) {
            var swiper = new Swiper('.year-swiper', {
                speed: 400,
                slidesPerView: 3,
                centeredSlides: true,
                slideToClickedSlide: true,
                navigation: {
                    prevEl: '.year-left-arrow',
                    nextEl: '.year-right-arrow',
                },
                breakpoints: {
                    991: {
                        slidesPerView: 9,
                    }
                }
            });
            swiper.on('slideChangeTransitionStart', function () {
                var year = $('.year-swiper .swiper-slide-active').attr('data-year');
                $('.history-slide').removeClass('active');
                setTimeout(function () {
                    $('.history-slide').removeClass('active');
                    $('.history-slide[data-year="' + year + '"]').addClass('active');
                }, 400);
            });
        }

        if ($('.images-container-mobile').length > 0) {
            var imageSwiper = new Swiper('.images-container-mobile', {
                speed: 400,
                slidesPerView: 1,
                spaceBetween: 10,
                navigation: {
                    prevEl: '.swiper-custom-prev',
                    nextEl: '.swiper-custom-next',
                }
            });
        }
    }

    function scrollToSection() {
        $('a[href*=\\#]').on('click', function (e) {
            if (this.pathname === window.location.pathname) {
                e.preventDefault();
                var minus_offter_top = 100;
                var thisHref = $(this).attr('href');
                if($(this).attr('data-sidebar')) {
                    var sidebarTrigger = $('[data-anchor="' + $(this).attr('href').replace('#', '') + '"]');
                    var sidebarHolder = sidebarTrigger.parents('.text-block-with-sidebar-holder');
                    var textBlock = sidebarHolder.find('.text-block#' + $(this).attr('href').replace('#', ''));
                    sidebarHolder.find('.text-block').removeAttr('style');
                    sidebarHolder.find('.sidebar-item').not(sidebarTrigger).removeClass('active');
                    sidebarTrigger.addClass('active');
                    sidebarHolder.find('.text-block').not(textBlock).removeClass('active');
                    textBlock.addClass('active');
                    minus_offter_top = 150;
                }
                var offset = $($(this).attr('href')).offset();
                if (typeof offset != 'undefined') {
                    $([document.documentElement, document.body]).stop().animate({
                        scrollTop: offset.top - minus_offter_top
                    }, 2000);
                }
            }
        });
    }

    function selectDropdown() {
        $('.select-dropdown-top').on('click', function () {
            $(this).closest('.select-dropdown').toggleClass('active');
        });

        $('.select-dropdown-option').on('click', function () {
            var values = [],
                names = [],
                parent = $(this).closest('.select-dropdown'),
                inputName = parent.attr('data-inputName'),
                category = $(this).closest('.select-dropdown').siblings('input[name="' + inputName + '"]');
            // There must be a hidden sibling input field next to .select-dropdown with the same name attribute as the $('.select-dropdown').attr('data-inputName');

            if (parent.hasClass('not-multi')) {
                values = [];
                names = [];
                parent.find('.custom-checkbox').removeClass('active');
                $(this).find('.custom-checkbox').addClass('active');
                parent.find('.select-dropdown-option').each(function () {
                    if ($(this).find('.custom-checkbox').hasClass('active')) {
                        values.push($(this).attr('data-value'));
                        names.push($(this).find('span').text());
                    }
                });
                if (names.length > 0) {
                    parent.find('.select-dropdown-top').text(names.join(', ')).addClass('selected');
                } else {
                    parent.find('.select-dropdown-top').text(parent.find('.select-dropdown-top').attr('data-placeholder')).removeClass('selected');
                }
                category.val(values).trigger('change');
            } else {
                if ($(this).attr('data-value') != 'all') {
                    parent.find('.select-dropdown-option[data-value="all"]').removeClass('active');
                    parent.find('.select-dropdown-option[data-value="all"]').find('.custom-checkbox').removeClass('active');
                    $(this).find('.custom-checkbox').toggleClass('active');
                    parent.find('.select-dropdown-option').each(function () {
                        if ($(this).find('.custom-checkbox').hasClass('active')) {
                            values.push($(this).attr('data-value'));
                            names.push($(this).find('span').text());
                        }
                    });
                    if (names.length > 0) {
                        parent.find('.select-dropdown-top').text(names.join(', ')).addClass('selected');
                    } else {
                        parent.find('.select-dropdown-top').text(parent.find('.select-dropdown-top').attr('data-placeholder')).removeClass('selected');
                    }
                    category.val(values).trigger('change');
                } else {
                    if (!$(this).hasClass('active')) {
                        parent.find('.select-dropdown-option').each(function () {
                            $(this).find('.custom-checkbox').addClass('active');
                            if ($(this).find('.custom-checkbox').hasClass('active')) {
                                if ($(this).attr('data-value') != 'all') {
                                    values.push($(this).attr('data-value'));
                                }
                            }
                        });

                        $(this).find('.custom-checkbox').addClass('active');
                        $(this).addClass('active');
                        parent.find('.select-dropdown-top').text(parent.find('.select-dropdown-top').attr('data-selectedAllPlaceholder')).addClass('selected');

                        category.val(values).trigger('change');
                    } else {
                        parent.find('.select-dropdown-option').each(function () {
                            $(this).find('.custom-checkbox').removeClass('active');
                            if ($(this).find('.custom-checkbox').hasClass('active')) {
                                values.push($(this).attr('data-value'));
                            }
                        });

                        $(this).find('.custom-checkbox').removeClass('active');
                        $(this).removeClass('active');
                        parent.find('.select-dropdown-top').text(parent.find('.select-dropdown-top').attr('data-placeholder')).removeClass('selected');
                        category.val(values).trigger('change');
                    }
                }
            }
        });

        $('body').on('click', function (e) {
            if ($(e.target).closest('.select-dropdown').length === 0) {
                $('.select-dropdown').removeClass('active');
            }
        });
    }

    function filterTeam() {
        if ($('.page-template-template-team').length > 0) {
            var ajax;
            $('input[name="areas"], input[name="position"], input[name="industries"], .search-input').on('change', function () {
                if (ajax) {
                    ajax.abort();
                }
                var searchInput = $('.search-input').val().split(',');
                var selectedAreas = $('input[name="areas"]').val().split(',');
                var selectedPositions = $('input[name="position"]').val().split(',');
                var selectedIndustries = $('input[name="industries"]').val().split(',');

                $('.selected-filters').empty();
                if (searchInput != '') {
                    $('.selected-filters').append('<div class="filter search-filter">' + searchInput + '</div>');
                }
                $(selectedAreas).each(function (index, value) {
                    var text = $('.select-dropdown-option[data-value="' + value + '"]').find('span').text();
                    if (text != '') {
                        $('.selected-filters').append('<div class="filter" data-value="' + value + '">' + text + '</div>');
                    }
                });
                $(selectedPositions).each(function (index, value) {
                    var text = $('.select-dropdown-option[data-value="' + value + '"]').find('span').text();
                    if (text != '') {
                        $('.selected-filters').append('<div class="filter" data-value="' + value + '">' + text + '</div>');
                    }
                });
                $(selectedIndustries).each(function (index, value) {
                    var text = $('.select-dropdown-option[data-value="' + value + '"]').find('span').text();
                    if (text != '') {
                        $('.selected-filters').append('<div class="filter" data-value="' + value + '">' + text + '</div>');
                    }
                });

                var data = {
                    'action': 'load_members',
                    'positions': $('input[name="position"]').val(),
                    'industries': $('input[name="industries"]').val(),
                    'areas': $('input[name="areas"]').val(),
                    'search': $('.search-input').val(),
                };
                $('.loading-screen').addClass('active');
                $('.no-posts').removeClass('active');
                $('.team-list').empty();
                $('.selected-filters').removeClass('active');

                ajax = $.get(postmpwp.ajax_url, data, function (response) {
                    if (response.data.members) {
                        $('.team-list').empty().append(response.data.members);
                        $('.selected-filters').addClass('active');
                        $('.loading-screen').removeClass('active');
                    } else {
                        $('.selected-filters').addClass('active');
                        $('.no-posts').addClass('active');
                        $('.loading-screen').removeClass('active');
                    }
                });
            });
            $(document).on('click', '.selected-filters .filter', function () {
                var slug = $(this).attr('data-value');
                $('.select-dropdown-option[data-value="' + slug + '"]').click();
                $(this).remove();
            });
            $(document).on('click', '.search-filter', function () {
                $('.search-input').val('').trigger('change');
                $(this).remove();
            });
        }
    }

    function search() {
        $('.search-icon').on('click', function () {
            if ($('.header-search-box').hasClass('active')) {
                $('.header-search-box').removeClass('active');
                setTimeout(function () {
                    $('.menu-item').removeClass('hidden');
                }, 500);
            } else {
                $('.menu-item').addClass('hidden');
                setTimeout(function () {
                    $('.header-search-box').addClass('active');
                }, 500);
            }
        });

        $('.search-icon-mobile').on('click', function () {
            if ($('.header-search-box-mobile').hasClass('active')) {
                $('.header-search-box-mobile').removeClass('active');
            } else {
                $('.header-search-box-mobile').addClass('active');
            }
        });

        $('.clear-icon').on('click', function () {
            $('.search-input').val('');
            $('.search-input-wrap').removeClass('has-value');
        });

        $('.search-input').on('keyup', function () {
            if ($(this).val() !== '') {
                $('.search-input-wrap').addClass('has-value');
            } else {
                $('.search-input-wrap').removeClass('has-value');
            }
        });
    }

    function mobileHeader() {
        $('.mobile-burger').on('click', function () {
            $(this).toggleClass('active');
            $('header').toggleClass('active');
            $('.mobile-menu').toggleClass('active');
            $('body').toggleClass('no-scroll');
        });
    }

    function statsSwiper() {
        if ($('.stats-mobile').length > 0) {
            var swiper = new Swiper('.stats-mobile', {
                speed: 400,
                slidesPerView: 1.2,
                spaceBetween: 10,
                centeredSlides: true
            });
        }
    }

    function menuActiveLink() {
        if ($('.single-team').length) {
            $('.menu-item-109').addClass('current-menu-item');
            $('.menu-item-14241').addClass('current-menu-item');
        } else if ($('.single-industries-expertise').length) {
            $('.menu-item-14242').addClass('current-menu-item');
            $('.menu-item-13959').addClass('current-menu-item');
        } else {
            if ($('.news').length > 0) {
                $('.menu-item-13944').addClass('current-menu-item');
                $('.menu-item-14243').addClass('current-menu-item');
            }
            if ($('.insights').length > 0) {
                $('.menu-item-13814').addClass('current-menu-item');
                $('.menu-item-14244').addClass('current-menu-item');
            }
        }
    }

    function leadMagnet() {
        var $container = $('.js-lead-magnet');

        $(document).on("submit", ".js-lead-form form", function (e) {
            e.preventDefault();
            const $form = $(this);
            const $email = $form.find('input[name="email"]');
            const $category = $form.find('input[name="category"]');
            const $visualCategory = $form.find('.select-dropdown');
            const $acceptance = $form.find('.js-lead-acceptance');
            const $success = $form.find('.js-success');

            var valid = true;

            if (($email.val().trim() === '') || !validateEmail($email.val().trim())) {
                valid = false;
                $email.addClass('error');
            } else {
                $email.removeClass('error');
            }

            if (($category.val().trim() === '')) {
                valid = false;
                $visualCategory.addClass('error');
            } else {
                $visualCategory.removeClass('error');
            }

            if ($acceptance.prop('required') && !$acceptance.prop('checked')) {
                valid = false;
                $acceptance.closest('.input-checkbox-container').addClass('error');
            } else {
                $acceptance.closest('.input-checkbox-container').removeClass('error');
            }

            if (valid) {

                $form.closest('.js-lead-form').addClass('loading')
                const formData = new FormData($form[0]);
                formData.append('action', 'handle_newsletter_file_download');
                // setTimeout(function () {
                //     // $email.val('');
                //     // $category.val('').trigger('change');
                //     // $acceptance.prop('checked', false);
                //     // $success.fadeOut(300);
                // }, 4600);

                $.ajax({
                    type: 'POST',
                    url: postmpwp.ajax_url,
                    data: formData,
                    dataType: 'json',
                    contentType: false,
                    processData: false,
                    cache: true,
                    success: function (response) {

                        if (response.success) {
                            $success.fadeIn(200);
                            const fileUrl = response.data.fileUrl ?? false;

                            if (fileUrl) {
                                // downloadFromUrl(fileUrl, fileUrl.substring(fileUrl.lastIndexOf('/') + 1));
                                downloadData(fileUrl);
                            }
                        }

                        $form.closest('.js-lead-form').removeClass('loading')
                    }
                })
            }
        });

        $(document).on("click", ".js-lead-magnet .js-show-lead-form", function () {
            var $this = $(this);
            $(this).fadeOut(300);
            setTimeout(function () {
                var $form = $container.find('.js-lead-form').fadeIn(300);
            }, 300);
        });


    }

    function downloadFromUrl(url, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        // console.log(name);
        // console.log(link);
        link.click();
        document.body.removeChild(link);
        delete link;
    }

    function downloadData(url) {

        const filename = url.split('/').pop().replace(/\.[^/.]+$/, '');

        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            })
            .catch(console.error);
    }

})(jQuery);